import styled from "styled-components";

export const NavbarStyles = styled.div`
  /* margin-top: 30px; */
  /* width: 250px;
  min-width: 250px;
  max-width: 307px; */
  width: 100%;
  /* height: 100%; */
  background-color: var(--color-background-primary);
  padding: 10px 12px;
  border-right: 1px solid var(--color-background-secondary);
  z-index: 990;
  ::-webkit-scrollbar {
    width: 3px;
    height: 5px;
    transform: scale(0.5); /* 스크롤바를 50% 축소 */
    transform-origin: 0 0;
  }
  ::-webkit-scrollbar-track {
    background-color: #ebe6e69b;
    /* background-color: transparent; */
  }
  ::-webkit-scrollbar-thumb {
    /* background-color: var(--color-logo-primary); */
    background: linear-gradient(
      0deg,
      rgba(0, 153, 99, 1) 100%,
      rgba(187, 212, 222, 1) 50%,
      rgba(255, 255, 255, 0) 30%
    );
    /* background: radial-gradient(
      circle,
      rgba(0, 153, 99, 1) 0%,
      rgba(255, 251, 252, 1) 100%
    ); */
    border-radius: 10px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }

  &.NavBar {
    display: block !important;
  }
  /* @media (max-width: 768px) {
    z-index: 993;
  }
  @media (max-width: 575px) {
    top: 145px;
  }
  @media (max-width: 572px) {
    top: 167px;
  }
  @media (max-width: 414px) {
    top: 154px;
  }
  @media (max-width: 374px) {
    top: 137px;
  }
  @media (max-width: 314px) {
    top: 160px;
  } */

  &.mobileNavBar {
    display: none;
    &.active {
      display: block;
      box-shadow: 0px 8px 9px 0px rgb(0 0 0 / 16%);
    }
  }
  .navBarContentWrap {
    /* width: 275px; */
    /* height: 232px; */
    width: 100%;
    overflow-x: auto;
    ul {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      /* flex-direction: column; */
      li {
        /* width: 220px; */
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        padding: 8px 12px;
        p {
          font-family: var(--font-family-primary);
          font-size: var(--font-size-md);
          font-weight: var(--font-weight-medium);
          line-height: 21px;
          white-space: nowrap;
          color: var(--color-label-primary);
          @media (max-width: 768px) {
            font-size: var(--mo-font-size-md);
          }
        }
        &:hover {
          background-color: var(--color-background-secondary);
          border-radius: 10px;
          cursor: pointer;
        }
        &.active {
          background-color: var(--color-background-secondary);
          border-radius: 10px;
        }
      }
    }
    .DepositAccount {
      margin-top: 27px;
      text-align: center;
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-bold);
      color: var(--color-logo-primary);
      border-width: 5px;
      border-style: solid;
      border-radius: 10px;
      border-color: var(--color-logo-primary);
      display: none;
      padding: 10px 0;
      small {
        font-size: var(--font-size-sm);
      }
      &.active {
        display: block;
      }
      @media (max-width: 768px) {
        margin-top: 5px;
        font-size: var(--mo-font-size-sm);
        small {
          font-size: var(--mo-font-size-xs);
        }
      }
    }
  }
`;
