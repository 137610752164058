import styled from "styled-components";
import Header from "../header";
import Infobar from "../infobar";
import Navbar from "../navbar";
import useWindowSize from "../../hooks/useWindowSize/useWindowSize";
import MainMenuNavbar from "../mainMenuNavbar";

export const HeaderWrapStyles = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
`;

const HeaderWrap = () => {
  const { width } = useWindowSize();
  return (
    <HeaderWrapStyles>
      <Header />
      <Infobar />
      {width <= 1024 && <MainMenuNavbar />}
      {width <= 1024 && <Navbar />}
    </HeaderWrapStyles>
  );
};

export default HeaderWrap;
