import { InfobarContainer } from "./styles";
import { messages } from "../../config";

const Infobar: React.FC = () => {
  return (
    <InfobarContainer>
      <div className="contentBox">
        <p>{messages.welcomeMessage}</p>
      </div>
    </InfobarContainer>
  );
};
export default Infobar;
