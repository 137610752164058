import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DepositAccount } from "../../config";
import { BlackBgStyles } from "./blackBgstyles";
import { NavbarStyles } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import changeImg from "process.env.PUBLIC_URL/images/icons/change.png";
import changeHistoryImg from "process.env.PUBLIC_URL/images/icons/change-history.png";
import orderImg from "process.env.PUBLIC_URL/images/icons/order.png";
import orderHistoryImg from "process.env.PUBLIC_URL/images/icons/order-history.png";
import imgDownloadImg from "process.env.PUBLIC_URL/images/icons/img-download.png";
import useWindowSize from "../../hooks/useWindowSize/useWindowSize";
import {
  useMobileNavBarContext,
  useLoginInfoContext,
} from "../../context/MyContext";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { width, height } = useWindowSize();
  const { isMobileNavBarTrue, setIsMobileNavBarTrue } =
    useMobileNavBarContext(); // 모바일 navBar Context API
  const { isLoggedIn } = useLoginInfoContext(); // 로그인 여부 Context
  const [isOrderPage, setIsOrderPage] = useState(false);

  // 검정 배경을 눌렀을 때 모달 close
  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // 클릭된 요소가 모달의 최상위 요소인지 확인
    if (e.target === e.currentTarget) {
      setIsMobileNavBarTrue(false);
    }
  };

  useEffect(() => {
    // 현재 경로가 '/order' 인지 확인
    if (
      location.pathname === "/order" ||
      location.pathname === "/order-history" ||
      location.pathname === "/cancel-exchange-return" ||
      location.pathname === "/cancel-exchange-return-history"
    ) {
      setIsOrderPage(true);
    } else {
      setIsOrderPage(false);
    }
  }, [location.pathname]); // location.pathname이 변경될 때마다 실행

  return (
    // <BlackBgStyles
    //   className={
    //     width <= 1024 ? (isMobileNavBarTrue ? "blackBg active" : "blackBg") : ""
    //   }
    //   onClick={handleOutsideClick}
    // >
    <>
      <NavbarStyles
        // className={
        //   width < 768 && isMobileNavBarTrue
        //     ? "mobileNavBar active"
        //     : width < 768 && isMobileNavBarTrue
        //     ? "mobileNavBar"
        //     : "NavBar"
        // }
        className={
          width <= 1024
            ? "mobileNavBar active"
            : // ? isMobileNavBarTrue
              //   ? "mobileNavBar active"
              //   : "mobileNavBar"
              "NavBar" // width가 768 이상일 때도 blackBg 클래스 적용
        }
      >
        <div className="navBarContentWrap">
          <ul>
            {width <= 1024 ? (
              <>
                <li
                  className={
                    location.pathname === "/all-products" ? "active" : ""
                  }
                  onClick={() => {
                    // navigate("/all-products");
                    navigate(
                      `/all-products?page=1&refresh=${new Date().getTime()}`
                    );
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/order.png`}
                    alt="orderIcon"
                  />
                  <p>전체상품</p>
                </li>
                <li
                  className={location.pathname === "/notice" ? "active" : ""}
                  onClick={() => {
                    navigate("/notice");
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/order.png`}
                    alt="orderIcon"
                  />
                  <p>공지사항</p>
                </li>
                <li
                  onClick={() => {
                    // navigate("/customer-service");
                    navigate(
                      `/customer-service?refresh=${new Date().getTime()}`
                    );
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/order.png`}
                    alt="orderIcon"
                  />
                  <p>고객센터</p>
                </li>
              </>
            ) : null}
            {isLoggedIn ? (
              <>
                <li
                  className={location.pathname === "/order" ? "active" : ""}
                  onClick={() => {
                    navigate("/order");
                  }}
                >
                  {/* <FontAwesomeIcon icon={faBox} /> */}
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/order.png`}
                    alt="orderIcon"
                  />
                  <p>주문하기</p>
                </li>
                <li
                  className={
                    location.pathname === "/order-history" ? "active" : ""
                  }
                  onClick={() => {
                    // navigate("/order-history");
                    navigate(`/order-history?refresh=${new Date().getTime()}`);
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/order-history.png`}
                    alt="orderIcon"
                  />
                  <p>주문내역</p>
                </li>
                <li
                  className={
                    location.pathname === "/detail-page-download"
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    // navigate("/detail-page-download");
                    navigate(
                      `/detail-page-download?refresh=${new Date().getTime()}`
                    );
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/img-download.png`}
                    alt="orderIcon"
                  />
                  <p>상세페이지 다운로드</p>
                </li>
                <li
                  className={
                    location.pathname === "/cancel-exchange-return"
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    // navigate("/cancel-exchange-return");
                    navigate(
                      `/cancel-exchange-return?refresh=${new Date().getTime()}`
                    );
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/change.png`}
                    alt="orderIcon"
                  />
                  <p>교환/환불 하기</p>
                </li>
                <li
                  className={
                    location.pathname === "/cancel-exchange-return-history"
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    // navigate("/cancel-exchange-return-history");
                    navigate(
                      `/cancel-exchange-return-history?refresh=${new Date().getTime()}`
                    );
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/change-history.png`}
                    alt="orderIcon"
                  />
                  <p>교환/환불 내역</p>
                </li>
              </>
            ) : null}
          </ul>
          <div
            className={isOrderPage ? "DepositAccount active" : "DepositAccount"}
          >
            <span>
              입금 계좌 <br />
              {DepositAccount.accountNumber} <br />
              {DepositAccount.bankName}
              <br />
              예금주 : {DepositAccount.accountHolder} <br />
              <br />
              <small>*{DepositAccount.infoText}</small>
            </span>
          </div>
        </div>
      </NavbarStyles>
    </>
    // </BlackBgStyles>
  );
};
export default Navbar;
