import styled from "styled-components";

export const PageWrapperStyles = styled.div`
  /* position: fixed; */
  /* top: 94px;
  left: 307px; // Navbar가 차지하는 너비 */
  /* width: calc(100% - 307px); // Navbar를 제외한 너비
  height: calc(100% - 94px); // 상단에서 94px 떨어진 높이 설정 */
  display: flex;
  flex-direction: column;
  overflow: hidden; // PageWrapper 자체는 고정하고 내부 콘텐츠만 스크롤 가능하게
`;
export const ContentStyles = styled.div`
  /* overflow-y: auto; // 내부 콘텐츠를 스크롤 가능하게 */
  display: flex;
  flex-direction: column;
  margin-top: 111px;
  @media (max-width: 1024px) {
    margin-top: 200px;
  }
  /* overflow-x: auto; */
  ::-webkit-scrollbar {
    width: 3px;
    height: 5px;
    transform: scale(0.5); /* 스크롤바를 50% 축소 */
    transform-origin: 0 0;
  }
  ::-webkit-scrollbar-track {
    background-color: #ebe6e69b;
    /* background-color: transparent; */
  }
  ::-webkit-scrollbar-thumb {
    /* background-color: var(--color-logo-primary); */
    background: linear-gradient(
      0deg,
      rgba(0, 153, 99, 1) 100%,
      rgba(187, 212, 222, 1) 50%,
      rgba(255, 255, 255, 0) 30%
    );
    /* background: radial-gradient(
      circle,
      rgba(0, 153, 99, 1) 0%,
      rgba(255, 251, 252, 1) 100%
    ); */
    border-radius: 10px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
`;
