import styled, { keyframes } from "styled-components";

const menuIconBackgroud = keyframes`
  0% {
   border-width: 1px 1px 1px 1px;
   border-style: solid;
   border-color: var(--color-logo-primary);
   background: #fff;
  }

 20% {
    background: var(--color-logo-primary);
  } 

  40% {
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: var(--color-logo-primary);
    background: #fff;
  }
  100% {
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: var(--color-logo-primary);
    background: #fff;
  }
`;

const menuIcon = keyframes`
  0% {
    transform: translate(-50%, -50%);
    color: var(--color-logo-primary);
  }
  10% {
    transform: translate(-50%, -30%);
    color: #fff;
  }
  15% {
    transform: translate(-50%, -50%);
    color: #fff;
  }
  20% {
    transform: translate(-50%, -30%);
    color: #fff;
  }
  30% {
    transform: translate(-50%, -50%);
    color: var(--color-logo-primary);
  }
  100% {
    transform: translate(-50%, -50%);
    color: var(--color-logo-primary);
  }
`;

export const HeaderStyles = styled.div`
  width: 100%;
  // min-width: 1280px;
  height: 98px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 30px 12px 30px;
  border-bottom: 1px solid #e5e8eb;
  background: #fff;
  z-index: 998;
  @media (max-width: 414px) {
    height: 86px;
  }
  @media (max-width: 375px) {
    height: 68px;
  }

  .leftContent {
    display: flex;
    align-items: center;
    gap: 5%;
    width: 100%;
    .logo {
      /* width: 145px; */
      padding: 5px 0;
      max-width: 250px;
      width: fit-content;
      cursor: pointer;
      @media (max-width: 575px) {
        max-width: 170px;
      }
      div {
        padding: 5px 0;
        img {
          max-width: 100%;
          height: auto;
          display: flex;
          align-items: center;
        }
      }
    }
    .moMenuIcon {
      width: 20px;
      height: 20px;
      border-radius: 2px;
      position: relative;
      border-width: 1px 1px 1px 1px;
      border-style: solid;
      border-color: var(--color-logo-primary);
      background: #fff;
      animation: ${menuIconBackgroud} 10s ease-in-out infinite;
      cursor: pointer;
      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: var(--mo-font-size-xs);
        animation: ${menuIcon} 10s ease-in-out infinite;
      }
    }
    ul {
      /* margin-left: 61.59px; */
      display: flex;
      gap: 24px;
      justify-content: space-between;

      li {
        font-size: var(--font-size-md);
        font-family: var(--font-family-primary);
        font-weight: 500;
        color: #0f1717;
        cursor: pointer;

        &.active {
          background-color: #f2ede8;
          border-radius: 12px;
          padding: 0 12px;
        }
      }
    }
  }

  .rightContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;

    /* gap: 64px; */
    .subMemu {
      width: 100%;
      ul {
        width: 100%;
        display: flex;
        justify-content: end;
        gap: 5%;
        /* gap: 18px; */
        color: var(--color-label-secondary);
        font-size: var(--font-size-sm);
        font-weight: 400;
        li {
          cursor: pointer;
        }
        @media (max-width: 314px) {
          font-size: var(--font-size-xs);
        }
      }
    }
  }
`;
